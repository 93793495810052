@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "proxima_nova_rgregular";
  src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot");
  src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff")
      format("woff"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf")
      format("truetype"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova_rgbold";
  src: url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot");
  src: url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff")
      format("woff"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf")
      format("truetype"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "adelle_rgregular";
  src: url("https://litmus.com/fonts/Emails/adelle_reg-webfont.eot");
  src: url("https://litmus.com/fonts/Emails/adelle_reg-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://litmus.com/fonts/Emails/adelle_reg-webfont.woff")
      format("woff"),
    url("https://litmus.com/fonts/Emails/adelle_reg-webfont.ttf")
      format("truetype"),
    url("https://litmus.com/fonts/Emails/adelle_reg-webfont.svg#adelle_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

.page {
  background-color: #060a0f;
}
.navbar {
  overflow: hidden;
  background-color: #060a0f81;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%;
  height: 10vh;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-page {
  margin-top: 10vh;
  background-color: rgba(255, 255, 255);
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.text-menu {
  font-size: 13pt;
  display: flex;
  gap: 5px;
  font-family: "proxima_nova_rgbold", sans-serif;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.text-menu:hover {
  color: #fab007;
}

.banner {
  width: 100%;
  height: 80vh;
  background-image: url("../../assets/images/banner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.banner-content {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: self-start;
  flex-direction: column;
}

.banner-title {
  width: 100%;
  font-size: 40pt;
  font-family: "proxima_nova_rgbold", sans-serif;
  font-weight: bold;
  color: white;
  margin: 0;
  text-align: left;
}
.banner-subtitle {
  width: 100%;
  font-size: 20pt;
  font-family: "proxima_nova_rgregular", sans-serif;
  font-weight: bold;
  color: white;
  margin: 0;
  text-align: left;
}
.bt-bold {
  border-bottom: 3px solid #0846d1;
  width: min-content;
  margin-bottom: 1rem;
}



.banner-button {
  width: 200px;
  height: 50px;
  border-radius: 25px;
  border: none;
  margin-top: 4vh;
  padding: 1.2vh 1.2vw;
  font-size: 12pt;
  background: linear-gradient(95deg, #0846d1 -39.65%, #fbbd00 256.53%);
  background-size: 200% 100%;
  background-position: 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-position 1s ease;
}

.banner-button:hover {
  background-position: -100% 0;
}

.bb-text {
  color: white;
  font-family: "proxima_nova_rgregular", sans-serif;
  font-size: 13pt;
  margin: 0;
}

.section {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
}

.section-in {
  width: 80%;
  display: flex;
  gap: 2vw;
  background-color: transparent;
}

.section-gr {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}

.section-2 {
  width: 80%;
}


.section-title {
  font-size: 36pt;
  font-family: "proxima_nova_rgbold", sans-serif;
  font-weight: bold;
  color: black;
  margin: 0;
}

.section-text {
  font-size: 12pt;
  font-family: "proxima_nova_rgregular", sans-serif;
  color: #424242;
  margin: 0;
  margin-top: 1vh;
}

.section-option-title {
  font-size: 14pt;
  font-family: "proxima_nova_rgregular", sans-serif;
  color: #424242;
  margin: 0;
  margin-top: 1vh;
  font-weight: bold;
}

.section-option-subtitle {
  font-size: 11pt;
  font-family: "proxima_nova_rgregular", sans-serif;
  color: #424242;
  margin: 0;
}
.section-title-mid {
  font-size: 13pt;
  font-family: "proxima_nova_rgregular", sans-serif;
  color: black;
  margin: 0;
}

.section-contact {
  width: 100%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../../assets/images/banner-contact.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.section-mid-title {
  font-size: 28pt;
  font-family: "proxima_nova_rgbold", sans-serif;
  font-weight: bold;
  color: white;
  margin: 0;
}

.section-mid-subtitle {
  font-size: 14pt;
  font-family: "proxima_nova_rgregular", sans-serif;
  color: white;
  margin: 0;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-form-item .ant-form-item-label >label {
  font-family: 'proxima_nova_rgregular', sans-serif;
  color: white;
  height: min-content;
  font-size: 12pt;
}


:where(.css-dev-only-do-not-override-1okl62o).ant-form-vertical .ant-form-item-label, :where(.css-dev-only-do-not-override-1okl62o).ant-col-24.ant-form-item-label, :where(.css-dev-only-do-not-override-1okl62o).ant-col-xl-24.ant-form-item-label {
padding: 0;
}

.ant-input-affix-wrapper {
  border-radius: 5px;
  border: none;
  padding: 0.5vh 1vw;
  font-size: 12pt;
  font-family: 'proxima_nova_rgregular', sans-serif;
  background-color: rgb(7, 0, 63);
  border: 0.5px solid white;
  color: white;
  display: flex;
  align-items: center;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-input-outlined:focus, :where(.css-dev-only-do-not-override-1okl62o).ant-input-outlined:focus-within{
  border-radius: 5px;
  border: none;
  padding: 0.5vh 1vw;
  font-size: 12pt;
  font-family: 'proxima_nova_rgregular', sans-serif;
  background-color: rgb(7, 0, 63);
  border: 0.5px solid white;
  color: white;
  display: flex;
  align-items: center;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

img {
  cursor: pointer;
}

.text-menu p{
  margin: 0;
}

input{
  background-color: rgb(7, 0, 63);
  height: 35px;
  width: -webkit-fill-available;
  border-radius: 5px;
  padding: 0.5vh 1vw;
  color: white;
  border: 0.5px solid white;
}

.login-form-button{
  height: 40px;
  border-radius: 5px;
  border: none;
  margin-top: 1vh;
  padding: 1vh 1vw;
  font-size: 12pt;
  background-color: #2b63db;
  color: white;
  font-family: 'proxima_nova_rgregular', sans-serif;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.login-form-button:hover {
  background-color: #2b63db;
}

.button{
  width: min-content;
  height: 40px;
  border-radius: 25px;
  margin-top: 1vh;
  padding: 1vh 2vw;
  font-size: 12pt;
  background-color: #2b63db;
  color: white;
  font-family: 'proxima_nova_rgregular', sans-serif;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.press-button{
  height: 40px;
  border-radius: 25px;
  padding: 1vh 1vw;
  font-size: 12pt;
  background-color: #2b63db;
  color: white;
  font-family: 'proxima_nova_rgregular', sans-serif;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-width: 0;
}

.button:hover, .press-button:hover{
  background-color: transparent;
  border: 1px solid #2b63db;
  color: #2b63db;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.445) !important;
  font-family: 'proxima_nova_rgregular', sans-serif !important;
  padding-left: 5px;
}

.footer {
  width: 100%;
  background-color: #060a0f;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 48px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 80%;
  border-top: 1px solid #9e9e9e;
  padding-top: 20px;
}

.footer-content .flex {
  display: flex;
}

.footer-content .gap-4 {
  gap: 1rem;
}

.footer-content .w-16 {
  width: 4rem;
}

.footer-content .footer-title,
.footer-content .footer-subtitle {
  font-weight: bold;
  color: white;
  font-family: "Noto Sans", sans-serif;
}

.footer-content .footer-text {
  color: white;
  font-size: 10pt;
  display: flex;
  gap: 1rem;
  font-family: 'proxima_nova_rgregular', sans-serif;
  cursor: pointer;
}

.footer-content .footer-text a {
  color: white;
  text-decoration: none;
  font-family: "Noto Sans", sans-serif;
}

.footer-content .footer-text a:hover {
  color: #fab007;
}

.footer-newsletter {
  border-radius: 10px;
  background: #0846d1;
  padding: 20px;
  width: 400px;
  margin-left: auto;
}

.newsletter-input {
  width: 100%;
  border-radius: 5px;
  border: none;
  padding-left: 10px;
  background-color: white;
  color: black;
}

.button-about {
  display: flex;
  padding: 10px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: var(
    --Style,
    linear-gradient(93deg, #face2e -47.72%, #fab007 63.65%)
  );
  box-shadow: 0px 5px 8px 0px rgba(222, 151, 0, 0.24),
    0px 14px 32px 0px rgba(255, 178, 14, 0.29);
  cursor: pointer;
}

.mid-button-title{
  font-size: 12pt;
  font-family: "proxima_nova_rgregular", sans-serif;
  color: white;
  margin: 0;
}

.button-about:hover {
  background: var(
    --Style,
    linear-gradient(93deg, #fab007 -47.72%, #face2e 63.65%)
  );
}

.newsletter-input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  padding-left: 10px;
}

label{
  color: white !important;
}
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "proxima_nova_rgregular";
  src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot");
  src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff")
      format("woff"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf")
      format("truetype"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova_rgbold";
  src: url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot");
  src: url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff")
      format("woff"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf")
      format("truetype"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "adelle_rgregular";
  src: url("https://litmus.com/fonts/Emails/adelle_reg-webfont.eot");
  src: url("https://litmus.com/fonts/Emails/adelle_reg-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://litmus.com/fonts/Emails/adelle_reg-webfont.woff")
      format("woff"),
    url("https://litmus.com/fonts/Emails/adelle_reg-webfont.ttf")
      format("truetype"),
    url("https://litmus.com/fonts/Emails/adelle_reg-webfont.svg#adelle_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

.banner-about {
  width: 100%;
  height: 50vh;
  background-image: url("../../assets/images/banner-about.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.banner-service {
  width: 100%;
  min-height: 50vh;
  background-image: url("../../assets/images/banner-service.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.banner-contact {
  width: 100%;
  min-height: 35vh;
  background-image: url("../../assets/images/banner-info.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.section-company {
  width: 100%;
  min-height: 50vh;
  background-image: url("../../assets/images/banner-team.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
}

.t-white {
  color: white;
}

.t-black {
  color: black;
  text-align: center;
  font-size: 17pt !important;
}

.t-black-2 {
  color: black;
  font-size: 16pt !important;
  text-align: left;
}

.t-mid-black {
  color: black;
  text-align: center;
  font-size: 12pt !important;
}

.t-mid-black-2 {
  color: black;
  text-align: left;
  font-size: 12pt !important;
}

.card-service{
  width: 100%;
  min-height: 10vh;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}